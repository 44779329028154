<template>
	<TheHeader hero="/images/screenshots/employees.png" :is-shadow="true">
		<template v-slot:title>
			ESOPs without a huge learning curve. <span class="line relative inline-block text-theme">Quickly.</span>
		</template>
		<template v-slot:description>
			Establish and manage employee share option plans without hours of advisors’ time. Use simple and intuitive interface, choose what you want to do, click the button, and perform the selected action with a few clicks.
		</template>
	</TheHeader>

	<section class="xl:py-28 lg:py-14 py-10 page-container flex md:flex-nowrap flex-wrap md:items-center md:space-x-12 md:space-y-0 space-y-6">
		<div class="xl:w-5/12 md:w-6/12 w-full">
			<WorkflowHead title="OptioPool" icon="/images/workflows/optiotrack-workflow.svg">
				Establish an option pool over a part of your shares
			</WorkflowHead>
			<DescriptionCheck data-aos="fade-up">
				Enter basic information on a company and your shareholding
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Choose what percentage of a company ownership will be initially allocated to employee participation
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Increase or decrease the size of option pool at any time
			</DescriptionCheck>
		</div>
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 w-full">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
			>
				<GifTutorial gif="optiopool.gif"/>
				<!--<VideoTutorial video-id="72cd2D6Xdbs" />-->
			</kinesis-element>
		</kinesis-container>
	</section>

	<section class="xl:py-28 lg:py-14 pt-10 pb-24 page-container flex md:flex-nowrap flex-wrap md:items-center md:space-x-12 md:space-y-0 space-y-16">
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 md:order-1 order-2 w-full md:mt-0 mt-5">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
				class=""
			>
				<GifTutorial gif="optioplan.gif"/>
				<!--<VideoTutorial video-id="72cd2D6Xdbs" />-->
			</kinesis-element>
		</kinesis-container>
		<div class="xl:w-5/12 md:w-6/12 md:order-2 order-1 w-full">
			<WorkflowHead title="OptioPlan" icon="/images/workflows/optioplan-workflow.svg">
				Create template-driven option plans
			</WorkflowHead>
			<DescriptionCheck data-aos="fade-up">
				Let Optiomat guide you through the process of creating option plans in accordance with a current best practice
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Create a professional Employee Share Option Plan tailored to each employee, just by clicking on clearly structured items in the displayed form, without the help of expensive external consultants
			</DescriptionCheck>
		</div>
	</section>

	<section class="xl:py-28 lg:py-14 py-10 page-container flex md:flex-nowrap flex-wrap md:items-center md:space-x-12 md:space-y-0 space-y-6">
		<div class="xl:w-5/12 md:w-6/12 w-full">
			<WorkflowHead title="OptioSign" icon="/images/workflows/optiosign-workflow.svg">
				Award option plans by signing digital documents right from your email inbox
			</WorkflowHead>
			<DescriptionCheck data-aos="fade-up">
				Eliminate paper-based manual processing of ESOP documents
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Generate digital documents, send them for acceptance and use online signature capabilities in accordance with the requirements for Advanced Electronic Signatures in terms of the eIDAS Regulation 910/2014 / EC
			</DescriptionCheck>
		</div>
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 w-full">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
			>
				<GifTutorial gif="optiosign.gif"/>
			</kinesis-element>
		</kinesis-container>
	</section>

	<section class="xl:py-28 lg:py-14 pt-10 pb-24 page-container flex md:flex-nowrap flex-wrap md:items-center md:space-x-12 md:space-y-0 space-y-16">
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 md:order-1 order-2 w-full md:mt-0 mt-5">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
				class=""
			>
				<GifTutorial gif="optiomanager.gif"/>
			</kinesis-element>
		</kinesis-container>
		<div class="xl:w-5/12 md:w-6/12 md:order-2 order-1 w-full">
			<WorkflowHead title="OptioManager" icon="/images/workflows/optiomanager-workflow.svg">
				Evaluate plan's milestones or settle a plan prematurely
			</WorkflowHead>
			<DescriptionCheck data-aos="fade-up">
				Determine whether the milestone-vested plan has been met
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Settle option plans prematurely in case of a new event such as a good leaver, bad leaver or sale of a company
			</DescriptionCheck>
		</div>
	</section>

	<section class="xl:py-28 lg:py-14 py-10 page-container flex md:flex-nowrap flex-wrap md:items-center md:space-x-12 md:space-y-0 space-y-6">
		<div class="xl:w-5/12 md:w-6/12 w-full">
			<WorkflowHead title="OptioView" icon="/images/workflows/optioview-workflow.svg">
				Get the complete picture of your option pool, ESOPs, employees, associated activities and transactions.
			</WorkflowHead>
			<DescriptionCheck data-aos="fade-up">
				Access all essential information from one place
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Always have an overview of what happened, what is happening and what is planned to happen
			</DescriptionCheck>
		</div>
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 w-full">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
			>
				<GifTutorial gif="optioview.gif"/>
			</kinesis-element>
		</kinesis-container>
	</section>

	<section class="xl:py-28 lg:py-14 py-10 page-container flex md:flex-nowrap flex-wrap md:items-center md:space-x-12 md:space-y-0 space-y-16">
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 md:order-1 order-2 w-full md:mt-0 mt-5">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
				class=""
			>
				<GifTutorial gif="optioplus.gif"/>
			</kinesis-element>
		</kinesis-container>
		<div class="xl:w-5/12 md:w-6/12 md:order-2 order-1 w-full">
			<WorkflowHead title="OptioPlus" icon="/images/workflows/optioplus-workflow.svg">
				Select a type of trust arrangement
			</WorkflowHead>
			<DescriptionCheck data-aos="fade-up">
				Connect with a trustee directly from your Optiomat account
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Get issued digital share certificates as a separate asset class for employee participation
			</DescriptionCheck>
			<DescriptionCheck data-aos="fade-up">
				Make share certificate allotments fully automated, paperless and notary-free
			</DescriptionCheck>
		</div>
	</section>
</template>
<script>
	import DescriptionCheck from "../components/DescriptionCheck";
	import VideoTutorial from "../components/VideoTutorial";
	import WorkflowHead from "../components/WorkflowHead";
	import GifTutorial from "../components/GifTutorial";
	import TitleCheck from "../components/TitleCheck";
	import TheHeader from "../components/TheHeader";

	export default {
		name: 'HowItWorksFounders',
		components: {
			DescriptionCheck,
			VideoTutorial,
			WorkflowHead,
			GifTutorial,
			TitleCheck,
			TheHeader,
		}
	}
</script>